import React from 'react';
import Icon1 from '../../images/box-bw.jpg';
import Icon2 from '../../images/tolva_dibujo.jpg';
import Icon3 from '../../images/monitoreo.jpg';
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP
} from './ServicesElements';

const Services = () => {
  return (
    <ServicesContainer id='services'>
      <ServicesH1>Nuestros Servicios</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon1} />
          <ServicesH2>Caja Seca</ServicesH2>
          <ServicesP>
            Equipo de 48' y 53'
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon2} />
          <ServicesH2>Tolvas</ServicesH2>
          <ServicesP>
            Equipo especializado para producto granulado no alimenticio
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon3} />
          <ServicesH2>Monitoreo</ServicesH2>
          <ServicesP>
            GPS y cuenta espejo para el dueño de la carga
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;

export const homeObjOne = {
  id: 'about',
  lightBg: false,
  lightText: false,
  lightTextDesc: true,
  topLine: 'Transporte Integral en Mérida',
  headline: 'Tu satisfacción Nuestra misión',
  description:
    'Somos un grupo de profesionales en transporte, ofrecemos diversos tipos de servicios para tus necesidades',
  imgStart: false,
  img: require('../../images/tracto1.jpg'),
  alt: 'Equipo',
  dark: true,
  primary: true,
  darkText: true
};

export const homeObjTwo2 = {
  id: 'discover',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Unlimited Access',
  headline: 'Login to your account at any time',
  description:
    'We have you covered no matter where you are located. All you need is an internet connection and a phone or computer.',
  buttonLabel: 'Learn More',
  imgStart: true,
  img: require('../../images/svg-2.svg'),
  alt: 'Piggybank',
  dark: false,
  primary: false,
  darkText: true
};


export const homeObjTwo = {
  id: 'contact',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '',
  headline: '',
  description:
    '',
  buttonLabel: '',
  imgStart: true,
  dark: false,
  primary: false,
  darkText: true
};



export const homeObjThree = {
  id: 'signup',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Join our Team',
  headline: 'Creating an account is extremely easy',
  description:
    "Get everything set up and ready in under 10 minutes. All you need to do is add your information and you're ready to go.",
  buttonLabel: 'Start Now',
  imgStart: false,
  img: require('../../images/svg-3.svg'),
  alt: 'Papers',
  dark: false,
  primary: false,
  darkText: true
};
